import { useContext } from 'react';

import { FeatureFlagContext } from 'lib/feature-flags/FeatureFlagWorkspaceContext';

export function useFeatureFlags() {
  const userData = useContext(FeatureFlagContext);
  return userData;
}

export default useFeatureFlags;
