import React, { useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  LinearProgress,
  Paper,
  styled
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import { AppointmentModel, ViewState } from '@devexpress/dx-react-scheduler';
import {
  AllDayPanel,
  Appointments,
  AppointmentTooltip,
  DateNavigator,
  MonthView,
  Resources,
  Scheduler,
  TodayButton,
  Toolbar,
  ViewSwitcher,
  WeekView
} from '@devexpress/dx-react-scheduler-material-ui';

import moment from 'moment';

import {
  Workspace_Campaigns_Bool_Exp,
  Workspace_Content_Type_Enum,
  useWorkspaceAgentMappingsQuery,
  useWorkspaceCampaignsCalendarQuery,
  Workspace_Campaign_Status_Enum,
  Workspace_Billing_Model_Enum,
  useStripeCustomActiveSubscriptionQuery,
  Workspace_Resource_Permission_Modes_Enum
} from 'generated/graphql';

import EmptyStatePage from 'components/EmptyStatePage';
import PageHeader from 'components/PageHeader';
import SchedulerTooltip from 'components/SchedulerTooltip';
import MultiFilterDropdown from 'components/multiFilterDropdown';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import { createFilter } from 'lib/hooks/useFiltersAndPagination';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import useUserContext from 'lib/hooks/useUserContext';
import { contentTypeColorMapping } from 'lib/utils/contentTypeColorMappings';

import CampaignTabs from '../CampaignTabs';

const useStatusFilter = createFilter<Workspace_Campaigns_Bool_Exp>({ status: {} });

const useContentTypeFilter = createFilter<Workspace_Campaigns_Bool_Exp>({ content_type: {} });

const usePlatformFilter = createFilter<Workspace_Campaigns_Bool_Exp, string>({
  ad_platforms: { _contains: null }
});

const resources = [
  {
    fieldName: 'appointmentColor',
    title: 'Content Type',
    instances: [
      { id: 'Draft', text: 'Draft', color: '#A9A9A9' },
      ...Object.entries(contentTypeColorMapping).map(([type, color]) => ({
        id: type,
        text: type,
        color: color
      }))
    ]
  }
];

const CampaignsCalendarPage = () => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const {
    activeWorkspaceId,
    isWorkspaceAgent,
    isWorkspaceAnalyst,
    isWorkspaceCreator,
    userId,
    workspace,
    isWorkspaceAdmin
  } = useUserContext();
  const agentPermissions = useAgentPermissions();
  const billingModel = useMemo(() => workspace?.billing_model, [workspace?.billing_model]);

  const [fromDate] = useState(moment().subtract(2, 'months'));

  const isEditingDisabled = isWorkspaceCreator || isWorkspaceAnalyst;

  // Filters
  const [filtersOpen, setFiltersOpen] = useState(true);
  const [contentTypeFilter, contentType, setContentType] = useContentTypeFilter();
  const [statusFilter, status, setStatus] = useStatusFilter();
  const [platformFilter, platform, setPlatform] = usePlatformFilter();

  const [filterAgentName, setFilterAgentName] = useLocalStorage(
    `properti.${activeWorkspaceId}.posts.filter.agent`
  );

  const campaignsWhere: Workspace_Campaigns_Bool_Exp = {
    _and: [
      { workspace_id: { _eq: activeWorkspaceId! } },
      { created_at: { _gte: fromDate.toISOString() } },
      { ...contentTypeFilter },
      { ...statusFilter },
      { ...platformFilter }
    ]
  };

  if (filterAgentName) {
    campaignsWhere._and!.push({
      _or: [
        {
          created_by: { display_name: { _eq: filterAgentName } }
        },
        {
          listings: {
            listing: {
              agents: { user: { display_name: { _eq: filterAgentName } } }
            }
          }
        }
      ]
    });
  }

  if (isWorkspaceAgent) {
    campaignsWhere._and!.push({
      _or: [
        {
          created_by_id: { _eq: userId! }
        },
        {
          agent_permission_mode: { _eq: Workspace_Resource_Permission_Modes_Enum.All }
        },
        {
          assigned_users: {
            user_id: { _eq: userId }
          }
        },
        {
          listings: {
            listing: {
              agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
            }
          }
        }
      ]
    });
  }

  const { data, loading } = useWorkspaceCampaignsCalendarQuery({
    variables: {
      workspace_id: activeWorkspaceId!,
      campaigns_where: campaignsWhere
    },
    context: workspaceMemberContext
  });

  const { data: agentsData } = useWorkspaceAgentMappingsQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });

  const { data: stripePaymentMethodsData, loading: stripePaymentMethodsLoading } =
    useStripeCustomActiveSubscriptionQuery({
      variables: {
        customerId: workspace?.stripe_customer_id!
      },
      context: workspaceMemberContext,
      skip: !workspace?.stripe_customer_id
    });
  const stripeCustomerStatus = stripePaymentMethodsData?.stripe_customer_status;
  const hasDefaultSource = useMemo(
    () => stripeCustomerStatus?.has_default_source ?? false,
    [stripeCustomerStatus]
  );

  const agents =
    agentsData?.workspace_agent_mapping
      ?.filter(
        (map) =>
          !isWorkspaceAgent ||
          agentPermissions.allowed_agents.includes(map.listing_agent_name.trim()) ||
          map.user_id === userId
      )
      ?.sort((a, b) => a.listing_agent_name.localeCompare(b.listing_agent_name)) ?? [];

  const agentNameOptions = agents
    ?.filter(Boolean)
    .map((agent) => ({ value: agent.user?.display_name!, text: agent.user?.display_name! }));

  const campaigns = useMemo(
    () =>
      data?.campaigns?.map((campaign) => ({
        title: campaign.name,
        id: campaign.id,
        created_by: campaign.created_by?.display_name ?? 'Properti Automation',
        startDate: campaign.start_date!,
        endDate: campaign.end_date!,
        type: 'campaign',
        status: campaign.status,
        appointmentColor:
          campaign.status === Workspace_Campaign_Status_Enum.Draft
            ? 'Draft'
            : campaign.content_type,
        contentType: campaign.content_type,
        allDay: true,
        metadata: {
          ...campaign
        }
      })) ?? [],
    [data?.campaigns]
  );

  const schedulerData: AppointmentModel[] = useMemo(() => [...campaigns], [campaigns]);

  // Direct users to add payment method if advertising workspace
  // and no payment method has been added to workspace
  if (
    billingModel === Workspace_Billing_Model_Enum.Advertising &&
    !hasDefaultSource &&
    !stripePaymentMethodsLoading
  ) {
    return (
      <EmptyStatePage
        title="Add payment method"
        text="To create campaigns, you need to add a valid payment method"
        icon={<CreditCardIcon color="secondary" fill="none" style={{ height: 64, width: 64 }} />}
        button={
          <Button
            component={Link}
            to="/billing#payment-methods"
            variant="contained"
            color="secondary"
            size="large"
            disabled={!isWorkspaceAdmin}
            fullWidth
          >
            Go to billing
          </Button>
        }
      />
    );
  }

  return (
    <>
      <PageHeader
        title="Calendar"
        rightComponent={
          <ButtonGroup
            variant="contained"
            color="secondary"
            disabled={isEditingDisabled}
            style={{ minWidth: 160 }}
          >
            <Button component={Link} to="/campaigns/create" size="large">
              Create Campaign
            </Button>
          </ButtonGroup>
        }
      />
      <CampaignTabs value={2} />
      <Paper elevation={0}>
        <MultiFilterDropdown
          onToggleFiltersOpen={() => setFiltersOpen((prev) => !prev)}
          filtersOpen={filtersOpen}
          search={false}
          menuItems={[
            {
              name: 'Platform',
              options: [
                { value: 'facebook', text: 'Meta' },
                { value: 'googleads', text: 'Google Ads' }
              ],
              value: platform,
              setValue: setPlatform,
              hasAll: true
            },
            {
              name: 'Content Category',
              options: [
                {
                  value: Workspace_Content_Type_Enum.AuctionClearanceRates,
                  text: 'Auction Clearances'
                },
                {
                  value: Workspace_Content_Type_Enum.AuthorityBuilding,
                  text: 'Authority Building'
                },
                {
                  value: Workspace_Content_Type_Enum.CallToAction,
                  text: 'Call to Action'
                },
                { value: Workspace_Content_Type_Enum.Listing, text: 'Listing' },
                { value: Workspace_Content_Type_Enum.MarketData, text: 'Market Data' },
                { value: Workspace_Content_Type_Enum.NewsUpdate, text: 'News Article' },
                {
                  value: Workspace_Content_Type_Enum.SocialProof,
                  text: 'Testimonials & Social Proof'
                },
                { value: Workspace_Content_Type_Enum.Personal, text: 'Personal' },
                { value: Workspace_Content_Type_Enum.GenericPost, text: 'Generic' },
                { value: Workspace_Content_Type_Enum.Other, text: 'Other' }
              ],
              value: contentType,
              setValue: setContentType,
              hasAll: true
            },
            {
              name: 'Status',
              options: ['draft', 'posted', 'posting', 'scheduled', 'error'],
              value: status,
              setValue: setStatus,
              hasAll: true
            },
            {
              name: 'Agent',
              value: filterAgentName,
              setValue: setFilterAgentName,
              options: agentNameOptions,
              hasAll: true
            }
          ]}
        />
        <Scheduler data={schedulerData}>
          <ViewState
            defaultCurrentDate={moment().format('YYYY-MM-DD')}
            defaultCurrentViewName="WorkWeek"
          />
          <WeekView startDayHour={19} endDayHour={20} cellDuration={60} />
          <WeekView
            name="WorkWeek"
            displayName="Work Week"
            startDayHour={19}
            endDayHour={20}
            excludedDays={[0, 6]}
            cellDuration={60}
          />
          <MonthView />
          <AllDayPanel layoutComponent={StyledAllDayLayoutComponent as any} />
          <Toolbar {...(loading ? { rootComponent: ToolbarWithLoading } : null)} />
          <DateNavigator />
          <TodayButton />
          <ViewSwitcher />
          <Appointments />
          <AppointmentTooltip
            headerComponent={HeaderComponent}
            contentComponent={ContentComponent}
          />
          <Resources data={resources} />
        </Scheduler>
      </Paper>
    </>
  );
};

const StyledAllDayLayoutComponent = styled(AllDayPanel.Layout)({
  height: '60vh'
});

const ToolbarWithLoading: React.FC<any> = ({ children, ...restProps }) => {
  return (
    <div>
      <Toolbar.Root {...restProps}>{children}</Toolbar.Root>
      <LinearProgress />
    </div>
  );
};

const ContentComponent = (props: any) => <SchedulerTooltip {...props} />;

const HeaderComponent = ({ onHide }: any) => {
  return (
    <Box
      style={{
        position: 'absolute',
        right: 0,
        zIndex: 1
      }}
    >
      <IconButton onClick={onHide}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default CampaignsCalendarPage;
