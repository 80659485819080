import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFileOutlined';
import AutoFixHighIcon from '@material-ui/icons/AutoFixHigh';
import ArticleIcon from '@material-ui/icons/DescriptionOutlined';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticonOutlined';
import LinkIcon from '@material-ui/icons/Link';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';

import { Picker } from 'emoji-mart';
import _ from 'lodash';
import { bindPopover, bindTrigger, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';
import styled from 'styled-components';

import {
  useGenerateAiPostMutation,
  useGenerateListingAiPostMessageMutation
} from 'generated/graphql';

import AttachmentDialog from 'components/AttachmentDialog';
import { AttachmentPreview } from 'components/AttachmentPreviewCard';
import ShareAttachmentPreview from 'components/ShareAttachmentPreview';
import ShortLinkDialog from 'components/ShortLinkDialog';
import { UppyUploader, UppyFileCheckOptions, UppyRestrictions } from 'components/Uppy';

import { getCloudinaryThumbnailFromVideoPath } from 'lib/Cloudinary';
import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { getFilePath } from 'lib/auth/hbp';

import { AttachmentEnum, Attachment, Image, FileAttachment } from '../types';
import { ShareLogicVariants } from '../types';

interface PostingFromInput {
  id: string;
  display_name?: string | null;
  avatar_url?: string | null;
  avatar_token?: string | null;
}
interface ShareInputValues {
  posting_from?: PostingFromInput;
  title?: string;
  privacy?: string;
  message?: string;
  url?: string | null;
  attachments?: Attachment[];
  post_type?: string;
}

interface ShareInputErrors {
  title?: string[];
  message?: string[];
  field?: string[];
  attachments?: string[];
}

export interface MergeTagOption {
  value: string;
  title: string;
  group?: string;
}

interface MergeTagMenuGroupProps {
  handleAddMergeTag: (mergeTag: MergeTagOption) => () => void;
  mergeTags: MergeTagOption[];
  groupName: string;
}

function MergeTagMenuGroup({ groupName, mergeTags, handleAddMergeTag }: MergeTagMenuGroupProps) {
  return (
    <>
      <MenuItem key={groupName} dense disabled>
        {groupName}
      </MenuItem>
      {mergeTags.map((mergeTag) => (
        <MenuItem key={mergeTag.value} onClick={handleAddMergeTag(mergeTag)}>
          {mergeTag.title ?? mergeTag.value}
        </MenuItem>
      ))}
    </>
  );
}

export interface ShareInputProps {
  mode?: 'workspace' | 'head-office';
  workspaceId: string;
  baseFileUploadPath: string;
  platform: 'generic' | 'facebook' | 'instagram' | 'youtube' | 'linkedin' | 'gmb';
  maxMessageLength?: number;
  rows?: number;
  hideAttachments?: boolean;
  disableAttachments?: boolean;
  hideUrl?: boolean;
  hideShortLink?: boolean;
  hideAIEnhancer?: boolean;
  filesLimit?: number;
  uppyFileCheckOptions?: UppyFileCheckOptions;
  uppyRestrictions?: UppyRestrictions;
  uppyNote?: string;
  onChange?: (value: ShareInputValues) => void;
  showTitleField?: boolean;
  values?: ShareInputValues;
  errors?: ShareInputErrors | string[];
  dirty?: boolean;
  onAddImage?: (ids: string[]) => Promise<Image | null | undefined>;
  listingId?: string;
  titlePlaceholderText?: string;
  placeholderText?: string;
  label?: string;
  titleLabel?: string;
  showPostingFromOption?: boolean;
  postingFromOptions?: PostingFromInput[];
  uppyAllowMultipleFileTypes?: boolean;
  mergeTags?: MergeTagOption[];
  getDefaultMessageToRestore?: () => string;
  shareVariant?: ShareLogicVariants;
}

const PLATFORMS_WITH_TITLES = ['youtube'];
const PLATFORMS_WITH_PRIVACY = ['youtube'];
const PLATFORMS_WITH_POST_TYPES = ['instagram', 'facebook'];

const ShareInput: React.FC<ShareInputProps> = ({
  workspaceId,
  baseFileUploadPath,
  platform,
  maxMessageLength,
  onChange,
  values,
  errors,
  rows = 6,
  filesLimit = 10,
  uppyRestrictions = {
    maxNumberOfFiles: 10,
    maxFileSize: 1100000000,
    allowedFileTypes: ['image/jpeg', 'image/png', 'image/jpg', 'video/mp4', 'video/mov']
  },
  uppyFileCheckOptions,
  uppyAllowMultipleFileTypes = false,
  uppyNote,
  hideAttachments = false,
  disableAttachments = false,
  hideUrl = false,
  hideShortLink = false,
  hideAIEnhancer = false,
  showTitleField = false,
  dirty = true,
  onAddImage,
  listingId,
  titlePlaceholderText = 'Title your post',
  placeholderText = 'Start typing your post copy here ...',
  label,
  titleLabel = 'Post title',
  showPostingFromOption = false,
  postingFromOptions,
  mergeTags = [],
  getDefaultMessageToRestore,
  shareVariant = ShareLogicVariants.UPLOAD,
  mode = 'workspace'
}) => {
  const defaultPostingFrom = postingFromOptions ? postingFromOptions![0] : undefined;
  const defaultPrivacy = platform === 'youtube' ? 'public' : undefined;
  const defaultPostType = platform === 'instagram' || platform === 'facebook' ? 'feed' : undefined;

  const inputRef = useRef<HTMLInputElement>();
  const [cursorPosition, setCursorPosition] = useState<number>();
  const [postingFrom, setPostingFrom] = useState<PostingFromInput | undefined>(
    values?.posting_from ?? defaultPostingFrom
  );
  const [title, setTitle] = useState<string | undefined>(values?.title);
  const [privacy, setPrivacy] = useState<string | undefined>(values?.privacy ?? defaultPrivacy);
  const [postType, setPostType] = useState<string | undefined>(
    values?.post_type ?? defaultPostType
  );
  const [message, setMessage] = useState<string | undefined>(values?.message);
  const [url, setUrl] = useState<string | null | undefined>(values?.url);
  const [attachments, setAttachments] = useState<Attachment[] | undefined>(values?.attachments);
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [attachmentOpen, setAttachmentOpen] = useState(false);
  const [shortLinkOpen, setShortLinkOpen] = useState(false);

  const emojiPopoverState = usePopupState({ variant: 'popover', popupId: 'emojis' });
  const mergeTagsPopoverState = usePopupState({ variant: 'popover', popupId: 'mergeTags' });

  const { workspaceMemberContext } = useHasuraRoleContext();
  const [generateWebsitePost, { loading: loadingWebsitePostMessage }] = useGenerateAiPostMutation({
    context: workspaceMemberContext
  });
  const [generateListingPost, { loading: loadingListingPostMessage }] =
    useGenerateListingAiPostMessageMutation({
      context: workspaceMemberContext
    });

  useEffect(() => {
    if (onChange) {
      // Equity check
      const newValues = {
        title,
        privacy,
        message,
        url,
        attachments,
        posting_from: postingFrom,
        post_type: postType
      };
      if (!_.isEqual(values, newValues)) {
        onChange!({
          title,
          privacy,
          message,
          attachments,
          url,
          posting_from: postingFrom,
          post_type: postType
        });
      }
    }
  }, [onChange, values, title, privacy, message, url, attachments, postingFrom, postType]);

  const handleClickEmoji = (emoji: any) => {
    const currentMessage = message ?? '';
    let newMessage = currentMessage.concat(emoji.native, ' ');

    if (cursorPosition || cursorPosition === 0) {
      const textBefore = currentMessage.substring(0, cursorPosition);
      const textAfter = currentMessage.substring(cursorPosition, currentMessage.length);
      newMessage = `${textBefore}${emoji.native} ${textAfter}`;
      setCursorPosition(cursorPosition + emoji.native.length + 1);
    }

    setMessage(newMessage);
  };

  const handleAddMergeTag = (mergeTag: MergeTagOption) => () => {
    const currentMessage = message ?? '';
    const mergeTagText = `${mergeTag.value}`;
    let newMessage = currentMessage.concat(mergeTagText, ' ');

    if (cursorPosition || cursorPosition === 0) {
      const textBefore = currentMessage.substring(0, cursorPosition);
      const textAfter = currentMessage.substring(cursorPosition, currentMessage.length);
      newMessage = `${textBefore}${mergeTagText}${textAfter}`;
      setCursorPosition(cursorPosition + mergeTagText.length + 1);
    }

    setMessage(newMessage);
    mergeTagsPopoverState.close();
  };

  const handleSelect = () => {
    const newCursorPosition = inputRef?.current?.selectionStart;
    if (newCursorPosition || newCursorPosition === 0) {
      setCursorPosition(newCursorPosition);
    }
  };

  const handleChangeMessage = (event: any) => {
    const newMessage = event.target.value;
    setMessage(newMessage);
  };

  const handleOnPasteMessage = (event: React.ClipboardEvent) => {
    const text = event.clipboardData.getData('Text');
    const numAttachments = attachments?.length ?? 0;
    if (!hideUrl && !url && isValidHttpUrl(text?.trim()) && numAttachments === 0) {
      handleSaveUrl(text?.trim());
    }
  };

  const handleChangeTitle = (event: any) => {
    const newTitle = event.target.value;
    setTitle(newTitle);
  };

  const handleChangePrivacy = (event: any) => {
    const newPrivacy = event.target.value;
    setPrivacy(newPrivacy);
  };

  const handleChangePostType = (event: any) => {
    setPostType(event.target.value);
  };

  const handleChangePostingFrom = (event: any) => {
    const newPostingFrom = event.target.value;
    setPostingFrom(newPostingFrom);
  };

  const handleOpenDropzone = () => {
    setDropzoneOpen(true);
    emojiPopoverState.close();
    mergeTagsPopoverState.close();
  };

  const handleCloseDropzone = () => {
    setDropzoneOpen(false);
  };

  const handleOpenAttachment = () => {
    setAttachmentOpen(true);
  };

  const handleCloseUrl = () => {
    setAttachmentOpen(false);
  };

  const handleOpenShortLink = () => {
    setShortLinkOpen(true);
  };

  const handleCloseShortLink = () => {
    setShortLinkOpen(false);
  };

  const handleSaveFiles = (newFiles: FileAttachment[]) => {
    if (newFiles.length > 0) {
      const oldAttachments = attachments ?? [];
      const newFileAttachments = newFiles.map((file) => ({
        type: AttachmentEnum.UPLOADED_FILE,
        uploaded_file: file
      }));
      const newAttachments = [...oldAttachments, ...newFileAttachments];
      handleCloseDropzone();
      setAttachments(newAttachments);
    }
  };

  const handleRemoveUrl = () => {
    const newUrl = null;
    setUrl(newUrl);
    handleCloseUrl();
  };

  const handleSaveUrl = (newUrl?: string | null) => {
    setUrl(newUrl);
    handleCloseUrl();
  };

  const handleInsertShortLink = (shortUrl: string) => {
    const currentMessage = message ?? '';
    const finalUrl = `https://${shortUrl}`;
    let newMessage = currentMessage.concat(finalUrl);

    if (cursorPosition || cursorPosition === 0) {
      const textBefore = currentMessage.substring(0, cursorPosition);
      const textAfter = currentMessage.substring(cursorPosition, currentMessage.length);
      newMessage = `${textBefore} ${finalUrl} ${textAfter}`;
      setCursorPosition(cursorPosition + finalUrl.length + 1);
    }

    setMessage(newMessage);
    if (!url && !attachments?.length) {
      setUrl(finalUrl);
    }
    handleCloseShortLink();
  };

  const handleMoveAttachment = useCallback(
    (dragId: number, hoverId: number) => {
      if (!attachments) {
        return;
      }

      const dragIndex = dragId as number;
      const hoverIndex = hoverId as number;

      const dragAttachment = attachments[dragIndex];

      if (dragAttachment) {
        const newAttachments = [...attachments];
        newAttachments.splice(dragIndex, 1);
        newAttachments.splice(hoverIndex, 0, dragAttachment);

        setAttachments(newAttachments);
      }
    },
    [setAttachments, attachments]
  );

  const handleDeleteAttachment = (idx: number) => {
    const newAttachments = attachments?.filter((_attachment, index) => index !== idx);
    setAttachments(newAttachments);
  };

  const handleAddImage = async () => {
    if (!onAddImage) {
      return;
    }

    try {
      const images = attachments?.filter(
        (attachment) => attachment.type === AttachmentEnum.LISTING_IMAGE
      );
      const ids =
        images?.map((attachment) => attachment?.image?.id)?.filter((id) => Boolean(id)) ?? [];
      const image = await onAddImage(ids as string[]);
      if (image) {
        const attachment = {
          type: AttachmentEnum.LISTING_IMAGE,
          image: image
        };
        const oldAttachments = attachments ?? [];
        const newAttachments = [...oldAttachments, attachment];

        setAttachments(newAttachments);
      }
    } catch (_error) {
      // noop
    }
  };

  const handleAIPostGeneration = async () => {
    const url = values?.url;
    if (listingId) {
      try {
        const { data, errors } = await generateListingPost({
          variables: { listing_id: listingId }
        });
        if (errors) {
          console.error(errors);
          return;
        }
        const content = data?.generateListingAIPostMessage?.content;
        if (content) {
          setMessage(content);
        }
      } catch (error) {
        console.error(error);
      }
    } else if (url) {
      try {
        const { data, errors } = await generateWebsitePost({ variables: { url } });
        if (errors) {
          console.error(errors);
          return;
        }
        const content = data?.generateAIPost?.content;
        if (content) {
          setMessage(content);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('Listing ID and URL is not defined! Cannot generate AI Post Message');
      return;
    }
  };

  const handleRestoreDefaultMessage = () => {
    if (getDefaultMessageToRestore) {
      const message = getDefaultMessageToRestore();
      setMessage(message);
    }
  };

  const normaliseErrors: ShareInputErrors | undefined =
    errors && Array.isArray(errors)
      ? {
          field: errors
        }
      : errors;

  const needsTitle = showTitleField || PLATFORMS_WITH_TITLES.includes(platform);
  const titleError = dirty && normaliseErrors?.title ? normaliseErrors.title[0] : undefined;
  const titleHasError = Boolean(titleError);

  const charCount = message?.length ?? 0;
  const isTooLong = maxMessageLength ? charCount > maxMessageLength : false;
  const charCountMessage = maxMessageLength ? `${charCount} / ${maxMessageLength}` : charCount;
  const messageError = dirty && normaliseErrors?.message ? normaliseErrors.message[0] : undefined;
  const attachmentError =
    dirty && normaliseErrors?.attachments ? normaliseErrors.attachments[0] : undefined;
  const messageHasError = isTooLong || Boolean(messageError) || Boolean(attachmentError);
  const messageFileError = isTooLong ? `Message is too long` : messageError ?? attachmentError;

  const fieldHasError = Boolean(normaliseErrors?.field);
  const fieldError =
    fieldHasError && normaliseErrors?.field ? normaliseErrors?.field[0] : undefined;

  const urlAttachmentWarning =
    attachments && attachments.length > 0 && Boolean(url) && platform !== 'gmb'
      ? `URL previews may not display properly when either image or video are attached`
      : undefined;
  const hasUrlAttachmentWarning = Boolean(urlAttachmentWarning);

  const errorMessage = messageFileError ?? fieldError;
  const hasError = messageHasError || fieldHasError;
  const hasErrorOrWarning = hasError || hasUrlAttachmentWarning;

  const loadingMessage = loadingListingPostMessage || loadingWebsitePostMessage;

  return (
    <div>
      {needsTitle && (
        <TextField
          value={title ?? ''}
          label={titleLabel}
          placeholder={titlePlaceholderText}
          helperText={titleError}
          error={titleHasError}
          fullWidth
          onChange={handleChangeTitle}
        />
      )}
      <PrivacySelect platform={platform} privacy={privacy} onChangePrivacy={handleChangePrivacy} />
      <PostTypeSelect
        platform={platform}
        postType={postType}
        onChangePostType={handleChangePostType}
        shareVariant={shareVariant}
      />
      <TextField
        inputRef={inputRef}
        label={label}
        multiline
        fullWidth
        minRows={showPostingFromOption ? 12 : rows}
        maxRows={showPostingFromOption ? 12 : rows}
        value={message ?? ''}
        onChange={handleChangeMessage}
        onPaste={handleOnPasteMessage}
        onSelect={handleSelect}
        error={hasError}
        placeholder={placeholderText}
        helperText={errorMessage ?? urlAttachmentWarning}
        disabled={loadingMessage}
        inputProps={{
          style: {
            marginTop: showPostingFromOption ? 25 : undefined,
            marginBottom: 25,
            overflowX: 'hidden'
          }
        }}
      />
      {showPostingFromOption && (
        <PostingFromContainer top={hasErrorOrWarning ? '-354px' : undefined}>
          <Typography style={{ marginRight: 8 }}>Posting from:</Typography>
          <Select
            value={postingFrom}
            onChange={handleChangePostingFrom}
            style={{ color: '#F47D6F', textOverflow: 'ellipsis', maxWidth: 380 }}
            renderValue={(option: any) => option.display_name}
            disableUnderline
          >
            {postingFromOptions?.map((option) => (
              // @ts-ignore
              <MenuItem key={option.id} value={option}>
                {option.display_name}
              </MenuItem>
            ))}
          </Select>
        </PostingFromContainer>
      )}
      <AddonContainer hasError={hasErrorOrWarning} hasPostingFromHeader={showPostingFromOption}>
        <div>
          <Tooltip title="Add emojis">
            <IconButton {...bindTrigger(emojiPopoverState)}>
              <InsertEmoticonIcon />
            </IconButton>
          </Tooltip>
          {!hideShortLink && (
            <Tooltip title="Insert Short Link">
              <IconButton onClick={handleOpenShortLink}>
                <LinkIcon />
              </IconButton>
            </Tooltip>
          )}
          {!hideAttachments && (
            <Tooltip title="Attach Files">
              <IconButton onClick={handleOpenDropzone}>
                <AttachFileIcon />
              </IconButton>
            </Tooltip>
          )}
          {!hideUrl && (
            <Tooltip title="Attach Link">
              <IconButton onClick={handleOpenAttachment}>
                <ArticleIcon />
              </IconButton>
            </Tooltip>
          )}
          {!hideAIEnhancer && (Boolean(url) || Boolean(listingId)) && (
            <Tooltip title="Enhance with AI">
              <IconButton onClick={handleAIPostGeneration} disabled={loadingMessage}>
                <AutoFixHighIcon />
                {loadingMessage && <CircularProgress size={20} />}
              </IconButton>
            </Tooltip>
          )}
          {mergeTags.length > 0 && (
            <Tooltip title="Merge tags">
              <IconButton {...bindTrigger(mergeTagsPopoverState)}>
                <MergeTypeIcon />
              </IconButton>
            </Tooltip>
          )}
          {Boolean(getDefaultMessageToRestore) && (
            <Tooltip title="Restore default message">
              <IconButton onClick={handleRestoreDefaultMessage} disabled={loadingMessage}>
                <RestoreOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          <Popover {...bindPopover(emojiPopoverState)}>
            <Picker
              set="apple"
              color="#17457A"
              emojiTooltip={true}
              enableFrequentEmojiSort={true}
              emoji={'point_up'}
              title={'Add an Emoji!'}
              onClick={handleClickEmoji}
            />
          </Popover>
          {mergeTags.length > 0 && (
            <Menu
              {...bindMenu(mergeTagsPopoverState)}
              PaperProps={{
                style: {
                  maxHeight: 400,
                  overflowY: 'scroll'
                }
              }}
            >
              {_.chain(mergeTags)
                .groupBy((t) => t.group ?? 'Merge Tags')
                .map((groupMergeTags, groupName) => (
                  <MergeTagMenuGroup
                    key={groupName}
                    groupName={groupName}
                    mergeTags={groupMergeTags}
                    handleAddMergeTag={handleAddMergeTag}
                  />
                ))
                .value()}
            </Menu>
          )}
          {!hideAttachments && (
            <UppyUploader
              baseFileUploadPath={baseFileUploadPath}
              workspaceId={workspaceId}
              onUppyCompleteSuccess={handleSaveFiles}
              uppyRestrictions={uppyRestrictions}
              uppyDashboardProps={{
                open: dropzoneOpen,
                closeModalOnClickOutside: true,
                onRequestClose: handleCloseDropzone,
                note: uppyNote
              }}
              uppyFileCheckOptions={uppyFileCheckOptions}
              allowMultipleFileTypes={uppyAllowMultipleFileTypes}
            />
          )}
          {!hideUrl && (
            <AttachmentDialog
              open={attachmentOpen}
              onClose={handleCloseUrl}
              onRemoveAttachment={handleRemoveUrl}
              onSaveAttachment={handleSaveUrl}
              attachmentUrl={url}
              mode={mode}
            />
          )}
          {!hideShortLink && (
            <ShortLinkDialog
              open={shortLinkOpen}
              onClose={handleCloseShortLink}
              onSave={handleInsertShortLink}
              listingId={listingId}
            />
          )}
        </div>
        <div>
          <Typography variant="overline">{charCountMessage}</Typography>
        </div>
      </AddonContainer>
      <Grid
        container
        spacing={1}
        style={{ position: 'relative', top: -20, marginLeft: 8, marginRight: 8 }}
      >
        {!hideUrl && <AttachmentPreview url={url} onRemoveUrl={handleRemoveUrl} />}
        {attachments?.map((attachment, idx) => {
          const { type, image, file, video, entry, uploaded_file } = attachment;
          if (
            (type === AttachmentEnum.LISTING_IMAGE || type === AttachmentEnum.CREATIVE_IMAGE) &&
            image
          ) {
            return (
              <ShareAttachmentPreview
                key={image.id}
                src={image.url}
                id={image.id}
                index={idx}
                onDelete={handleDeleteAttachment}
                moveItem={handleMoveAttachment}
                disabled={disableAttachments}
              />
            );
          } else if (
            video &&
            (type === AttachmentEnum.CREATIVE_VIDEO || type === AttachmentEnum.REALSHORTZ_VIDEO)
          ) {
            return (
              <ShareAttachmentPreview
                key={video.id}
                id={video.id}
                src={video.thumbnail_url}
                index={idx}
                onDelete={handleDeleteAttachment}
                moveItem={handleMoveAttachment}
                disabled={disableAttachments}
                showVideoIcon
              />
            );
          } else if (
            (type === AttachmentEnum.CREATIVE_FILE || type === AttachmentEnum.CAROUSEL_ITEM) &&
            entry
          ) {
            let imageSrc = getFilePath(entry.path, entry.token);
            return (
              <ShareAttachmentPreview
                key={entry.id}
                id={entry.id}
                src={imageSrc}
                index={idx}
                onDelete={handleDeleteAttachment}
                moveItem={handleMoveAttachment}
                disabled={disableAttachments}
              />
            );
          } else if (type === AttachmentEnum.UPLOADED_FILE && uploaded_file) {
            const isVideo = uploaded_file.contenttype.includes('video');

            if (isVideo) {
              const src =
                getFilePath(uploaded_file.thumbnail_path) ??
                getFilePath(uploaded_file.generated_thumbnail_path) ??
                getCloudinaryThumbnailFromVideoPath(uploaded_file.key.replace(/\.[^/.]+$/, '')) ?? // Remove file extension from path
                undefined;

              return (
                <ShareAttachmentPreview
                  key={uploaded_file.key}
                  id={uploaded_file.key}
                  src={src}
                  index={idx}
                  onDelete={handleDeleteAttachment}
                  moveItem={handleMoveAttachment}
                  disabled={disableAttachments}
                  showVideoIcon={true}
                  useSrcAsThumbnail={
                    !Boolean(uploaded_file.thumbnail_path) &&
                    !Boolean(uploaded_file.generated_thumbnail_path)
                  }
                />
              );
            }

            const src = getFilePath(uploaded_file.key, uploaded_file.token);

            return (
              <ShareAttachmentPreview
                key={uploaded_file.key}
                id={uploaded_file.key}
                src={src}
                index={idx}
                onDelete={handleDeleteAttachment}
                moveItem={handleMoveAttachment}
                disabled={disableAttachments}
                showVideoIcon={false}
              />
            );
          } else if (type === AttachmentEnum.FILE && file) {
            let src: string | undefined = undefined;
            if (file.type.startsWith('image/')) {
              src = URL.createObjectURL(file);
            }

            return (
              <ShareAttachmentPreview
                key={file.name}
                src={src}
                id={file.name}
                index={idx}
                onDelete={handleDeleteAttachment}
                moveItem={handleMoveAttachment}
                disabled={disableAttachments}
              />
            );
          }
          return null;
        })}
        {onAddImage && attachments && attachments.length < filesLimit && (
          <AddImageContainer>
            <IconButton onClick={handleAddImage}>
              <AddIcon />
            </IconButton>
          </AddImageContainer>
        )}
      </Grid>
    </div>
  );
};

export default ShareInput;

interface AddonContainerProps {
  hasError: boolean;
  hasPostingFromHeader: boolean;
  children: React.ReactNode;
}

const AddonContainer: React.FC<AddonContainerProps> = ({
  hasError,
  hasPostingFromHeader,
  children
}) => {
  let top = 50;

  if (hasError) {
    top += 24;
  }

  if (hasPostingFromHeader) {
    top += 30;
  }

  return <AddonContainerDiv top={`-${top}px`}>{children}</AddonContainerDiv>;
};

const AddonContainerDiv = styled.div.attrs((props: any) => ({
  top: props.top || '-80px'
}))`
  position: relative;
  top: ${(props) => props.top};
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  border-top: 1px solid #ebeff2;
`;

const AddImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 100px;
`;

const PostingFromContainer = styled.div.attrs((props: any) => ({
  top: props.top || '-329px'
}))`
  position: relative;
  top: ${(props) => props.top};
  height: 30px;
  padding-left: 8px;
  text-align: left;
  fontsize: 14;
  fontweight: 700;

  border-bottom: 1px solid #ebeff2;
  margin-left: 8px;
  margin-right: 8px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
`;

function isValidHttpUrl(text: string) {
  let url;

  try {
    url = new URL(text);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

interface PostTypeSelectProps {
  platform: 'generic' | 'facebook' | 'instagram' | 'youtube' | 'linkedin' | 'gmb';
  postType?: string;
  onChangePostType: (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => void;
  shareVariant: ShareLogicVariants;
}

function PostTypeSelect({
  platform,
  postType,
  onChangePostType,
  shareVariant
}: PostTypeSelectProps) {
  const doesPlatformNeedPostType = PLATFORMS_WITH_POST_TYPES.includes(platform);
  if (!doesPlatformNeedPostType) {
    return null;
  }

  if (platform === 'instagram') {
    const instagramStoriesDisabled = [ShareLogicVariants.CONTENT_RSS].includes(shareVariant);

    const instagramReelsDisabled = [
      ShareLogicVariants.CAROUSEL,
      ShareLogicVariants.CONTENT_RSS,
      ShareLogicVariants.LISTING
    ].includes(shareVariant);

    return (
      <div style={{ textAlign: 'left' }}>
        <FormControl variant="outlined" style={{ minWidth: '100%', paddingTop: 8 }}>
          <InputLabel id="post-type-label">Post Type</InputLabel>
          <Select labelId="post-type-label" value={postType} onChange={onChangePostType}>
            <MenuItem value="feed">Feed</MenuItem>
            <MenuItem value="reel" disabled={instagramReelsDisabled}>
              Reels
            </MenuItem>
            <MenuItem value="stories" disabled={instagramStoriesDisabled}>
              Stories
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }

  if (platform === 'facebook') {
    const facebookStoriesDisabled = [
      ShareLogicVariants.CAROUSEL,
      ShareLogicVariants.CONTENT_RSS
    ].includes(shareVariant);
    const facebookReelsDisabled = [
      ShareLogicVariants.CAROUSEL,
      ShareLogicVariants.CONTENT_RSS,
      ShareLogicVariants.LISTING
    ].includes(shareVariant);

    return (
      <div style={{ textAlign: 'left' }}>
        <FormControl variant="outlined" style={{ minWidth: '100%', paddingTop: 8 }}>
          <InputLabel id="post-type-label">Post Type</InputLabel>
          <Select labelId="post-type-label" value={postType} onChange={onChangePostType}>
            <MenuItem value="feed">Feed</MenuItem>
            <MenuItem value="reel" disabled={facebookReelsDisabled}>
              Reels
            </MenuItem>
            <MenuItem value="stories" disabled={facebookStoriesDisabled}>
              Stories
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }

  // Ideally the doesPlatformNeedPostType check should cover anything before this point
  return null;
}

interface PrivacySelectProps {
  platform: 'generic' | 'facebook' | 'instagram' | 'youtube' | 'linkedin' | 'gmb';
  privacy?: string;
  onChangePrivacy: (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => void;
}

function PrivacySelect({ platform, privacy, onChangePrivacy }: PrivacySelectProps) {
  const doesPlatformNeedPrivacy = PLATFORMS_WITH_PRIVACY.includes(platform);
  if (!doesPlatformNeedPrivacy) {
    return null;
  }

  if (platform === 'youtube') {
    return (
      <div style={{ textAlign: 'left' }}>
        <FormControl variant="outlined" style={{ minWidth: '100%', paddingTop: 8 }}>
          <InputLabel id="privacy-label">Privacy</InputLabel>
          <Select labelId="privacy-label" value={privacy} onChange={onChangePrivacy}>
            <MenuItem value="public">Public</MenuItem>
            <MenuItem value="unlisted">Unlisted </MenuItem>
            <MenuItem value="private">Private</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }

  // Ideally the doesPlatformNeedPrivacy check should cover anything before this point
  return null;
}
