/** @jsxImportSource preact/compat */
import Uppy, { UIPlugin, DefaultPluginOptions } from '@uppy/core';

import { Component, h as React } from 'preact';

import { createFileFromUrl } from 'lib/utils/files';

function CanvaIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="28"
        height="28"
      >
        <path
          d="M16 29.3334C23.3638 29.3334 29.3333 23.3638 29.3333 16C29.3333 8.63622 23.3638 2.66669 16 2.66669C8.63619 2.66669 2.66666 8.63622 2.66666 16C2.66666 23.3638 8.63619 29.3334 16 29.3334Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.33331 1.33337H30.6666V30.6667H1.33331V1.33337Z"
          fill="#00C4CC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4773 18.628C21.4479 18.498 21.3762 18.3814 21.2734 18.2965C21.1706 18.2115 21.0426 18.1631 20.9093 18.1587C20.8021 18.1551 20.696 18.182 20.6035 18.2363C20.511 18.2906 20.4358 18.37 20.3866 18.4654C20.3306 18.5507 20.28 18.6374 20.232 18.7187C20.1965 18.7797 20.16 18.8402 20.1226 18.9C19.496 19.8867 19.0026 20.324 17.8333 20.936C17.3078 21.2065 16.7269 21.352 16.136 21.3614C14.416 21.3614 13.416 19.8387 13.0826 18.5347C12.496 16.2254 13.4693 13.352 14.736 11.708C15.4546 10.7734 16.2573 10.2347 16.9386 10.232C17.1224 10.2379 17.3021 10.2874 17.463 10.3765C17.6239 10.4655 17.7613 10.5915 17.864 10.744C18.2213 11.3187 18.3373 11.8027 17.9306 12.712C17.8729 12.8495 17.8671 13.0032 17.9145 13.1446C17.9618 13.2859 18.0591 13.4052 18.188 13.48C18.528 13.6614 18.9466 13.4574 19.4013 12.8054C19.8813 12.1187 19.912 11.04 19.472 10.3494C18.9293 9.50005 17.8786 8.97205 16.7093 8.97205C15.893 8.97931 15.0946 9.21236 14.4026 9.64538C11.5226 11.4654 9.87196 15.668 10.724 19.012C11.028 20.2054 11.6973 21.284 12.5586 21.9747C12.8973 22.2414 14.112 23.112 15.5853 23.112H15.6026C17.088 23.1054 18.216 22.4747 18.9386 21.9987C19.728 21.4734 20.416 20.7507 21.0453 19.7894C21.0973 19.7107 21.148 19.6294 21.1973 19.5467L21.336 19.3214C21.4063 19.2219 21.4552 19.1089 21.4795 18.9896C21.5038 18.8702 21.5031 18.7471 21.4773 18.628ZM15.5853 22.8067V22.808V22.8067Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

const PRESETS = [
  {
    value: 'custom',
    name: 'Custom',
    width: 1080,
    height: 1080
  },
  {
    value: 'square',
    name: 'Square (1080 x 1080px)',
    width: 1080,
    height: 1080
  },
  {
    value: 'story',
    name: 'Story (1080 x 1920px)',
    width: 1080,
    height: 1920
  },
  {
    value: 'fb-post',
    name: 'Facebook post (940 x 788px)',
    width: 940,
    height: 788
  },
  {
    value: 'fb-ad',
    name: 'Facebook ad (1200 x 628px)',
    width: 1200,
    height: 928
  }
];

export default class UppyCanvaPlugin extends UIPlugin {
  static VERSION = '1.0.0';

  title: string;
  icon: () => JSX.Element;
  opts: any;

  width: number;
  height: number;
  preset: string;

  constructor(uppy: Uppy, opts: DefaultPluginOptions = {}) {
    super(uppy, opts);
    this.id = opts.id || 'Canva';
    this.title = opts.title || 'Canva';
    this.type = 'acquirer';

    this.width = opts.width || 1080;
    this.height = opts.height || 1080;
    this.preset = opts.preset || 'custom';

    const defaultOptions = {};

    this.opts = { ...defaultOptions, ...opts };

    this.icon = () => <CanvaIcon />;

    // Bind `this` to class methods
    this.addFile = this.addFile.bind(this);
    this.render = this.render.bind(this);
  }

  addFile(file: File) {
    try {
      this.uppy.addFile({
        source: this.id,
        name: file.name,
        type: file.type,
        data: file,
        meta: {
          relativePath: null
        }
      });
    } catch (err: any) {
      this.uppy.log(err);
      this.uppy.info({ message: 'Cannot Add File', details: err }, 'error');
    }
  }

  render() {
    const handleInputStateChange = (state: PresetDimensionsInputState) => {
      this.width = state.width;
      this.height = state.height;
      this.preset = state.preset;
    };

    const onClickDesign = () => {
      if ((window as any).CanvaApi) {
        (window as any).CanvaApi.createDesign({
          design: {
            type: 'SocialMedia',
            dimensions: {
              width: this.width,
              height: this.height
            }
          },
          editor: {
            fileType: 'jpeg'
          },
          onDesignPublish: ({ exportUrl, designId }: { exportUrl: string; designId: string }) => {
            createFileFromUrl(exportUrl, `${designId}.jpeg`).then(this.addFile);
          }
        });
      }
    };

    return (
      <div className="uppy-Url">
        <PresetDimensionsInput
          defaultPreset={this.preset}
          defaultHeight={this.height}
          defaultWidth={this.width}
          onChange={handleInputStateChange}
        />

        <button
          className="uppy-u-reset uppy-c-btn uppy-c-btn-primary uppy-Url-importButton"
          style={{ backgroundColor: '#7d2ae8', color: '#fff' }}
          onClick={onClickDesign}
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <CanvaIcon />
            <span>Design on Canva</span>
          </span>
        </button>
      </div>
    );
  }

  install() {
    const { target } = this.opts;
    if (target) {
      this.mount(target, this as any);
    }
  }

  uninstaller() {
    this.unmount();
  }
}

interface PresetDimensionsInputProps {
  defaultPreset: string;
  defaultWidth: number;
  defaultHeight: number;
  onChange: (state: PresetDimensionsInputState) => void;
}

interface PresetDimensionsInputState {
  preset: string;
  width: number;
  height: number;
}

class PresetDimensionsInput extends Component<
  PresetDimensionsInputProps,
  PresetDimensionsInputState
> {
  constructor(props: PresetDimensionsInputProps) {
    super(props);
    this.state = {
      preset: props.defaultPreset,
      width: props.defaultWidth,
      height: props.defaultHeight
    };
  }

  componentWillUpdate(
    _nextProps: Readonly<PresetDimensionsInputProps>,
    nextState: Readonly<PresetDimensionsInputState>,
    _nextContext: any
  ): void {
    this.props.onChange(nextState);
  }

  handleChangeNumber = (dimension: 'width' | 'height') => (e: any) => {
    const value = parseInt(e.target.value);
    if (dimension === 'height') {
      this.setState((prevState) => ({ ...prevState, height: value }));
    } else if (dimension === 'width') {
      this.setState((prevState) => ({ ...prevState, width: value }));
    }
  };

  handleChangePreset = (event: any) => {
    const newPresetValue = event.target.value;
    const foundPreset = PRESETS.find((p) => p.value === newPresetValue);

    if (foundPreset && newPresetValue === 'custom') {
      this.setState((prevState) => ({ ...prevState, preset: newPresetValue }));
    } else if (foundPreset) {
      this.setState({
        preset: newPresetValue,
        width: foundPreset.width,
        height: foundPreset.height
      });
    }
  };

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around'
        }}
      >
        <div>
          <label>Preset</label>
          <select
            className="uppy-u-react uppy-c-textInput uppy-Url-input"
            placeholder="Preset"
            value={this.state.preset}
            data-uppy-super-focusable
            onChange={this.handleChangePreset}
            style={{ width: 400, margin: 12 }}
          >
            <option value="custom">Custom</option>
            <option value="square">Square (1080 x 1080px)</option>
            <option value="story">Story (1080 x 1920px)</option>
            <option value="fb-post">Facebook post (940 x 788px)</option>
            <option value="fb-ad">Facebook ad (1200 x 628px)</option>
          </select>
        </div>

        {this.state.preset === 'custom' && (
          <div>
            <label>Width</label>
            <input
              className="uppy-u-reset uppy-c-textInput uppy-Url-input"
              type="number"
              placeholder="Width"
              min={500}
              max={2000}
              step={100}
              value={this.state.width}
              onChange={this.handleChangeNumber('width')}
              data-uppy-super-focusable
              style={{ width: 400, margin: 12 }}
            />
          </div>
        )}

        {this.state.preset === 'custom' && (
          <div>
            <label>Height</label>
            <input
              className="uppy-u-reset uppy-c-textInput uppy-Url-input"
              placeholder="Height"
              type="number"
              value={this.state.height}
              min={500}
              max={2000}
              step={100}
              onChange={this.handleChangeNumber('height')}
              data-uppy-super-focusable
              style={{ width: 400, margin: 12 }}
            />
          </div>
        )}
      </div>
    );
  }
}
