import { useMemo } from 'react';

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CrossIcon from '@material-ui/icons/Clear';

import numbro from 'numbro';

import {
  useAvailableWorkspaceSubscriptionsFromHeadOfficeQuery,
  Workspace_Subscription_Model_Enum
} from 'generated/graphql';

import TooltipComponent from 'components/Tooltip';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

export default function FeatureComparisonTable() {
  const { activeWorkspaceId, workspace } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();

  const { data, loading } = useAvailableWorkspaceSubscriptionsFromHeadOfficeQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });

  const hasHeadOffice = useMemo(
    () => (workspace?.head_offices?.length ?? 0) >= 1,
    [workspace?.head_offices?.length]
  );

  const options = useMemo(
    () => data?.head_office_workspace_subscription_options ?? [],
    [data?.head_office_workspace_subscription_options]
  );

  const entry = useMemo(
    () =>
      options.find(
        (option) => option.subscription_level === Workspace_Subscription_Model_Enum.Entry
      ),
    [options]
  );
  const hasEntry = useMemo(() => Boolean(entry) && entry!.enabled, [entry]);

  const basic = useMemo(
    () =>
      options.find(
        (option) => option.subscription_level === Workspace_Subscription_Model_Enum.Basic
      ),
    [options]
  );
  const hasBasic = useMemo(
    () => !hasHeadOffice || (Boolean(basic) && basic!.enabled),
    [hasHeadOffice, basic]
  );

  const premium = useMemo(
    () =>
      options.find(
        (option) => option.subscription_level === Workspace_Subscription_Model_Enum.Premium
      ),
    [options]
  );
  const hasPremium = useMemo(
    () => !hasHeadOffice || (Boolean(premium) && premium!.enabled),
    [hasHeadOffice, premium]
  );

  const premiumPlus = useMemo(
    () =>
      options.find(
        (option) => option.subscription_level === Workspace_Subscription_Model_Enum.PremiumPlus
      ),
    [options]
  );
  const hasPremiumPlus = useMemo(
    () => !hasHeadOffice || (Boolean(premiumPlus) && premiumPlus!.enabled),
    [hasHeadOffice, premiumPlus]
  );

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          {hasEntry && <TableCell>{entry?.name ?? 'Entry'}</TableCell>}
          {hasBasic && <TableCell>{basic?.name ?? 'Basic'}</TableCell>}
          {hasPremium && <TableCell>{premium?.name ?? 'Premium'}</TableCell>}
          {hasPremiumPlus && <TableCell>{premiumPlus?.name ?? 'Premium Plus'}</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Users</TableCell>
          {hasEntry && <TableCell>{entry?.users ?? 1} user</TableCell>}
          {hasBasic && <TableCell>{basic?.users ?? 2} users</TableCell>}
          {hasPremium && <TableCell>{premium?.users ?? 10} users</TableCell>}
          {hasPremiumPlus && <TableCell>{premiumPlus?.users ?? 100} users</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell>Social Accounts (per platform)</TableCell>
          {hasEntry && <TableCell>{entry?.social_accounts ?? 1} accounts</TableCell>}
          {hasBasic && <TableCell>{basic?.social_accounts ?? 1} accounts</TableCell>}
          {hasPremium && <TableCell>{premium?.social_accounts ?? 10} accounts</TableCell>}
          {hasPremiumPlus && <TableCell>{premiumPlus?.social_accounts ?? 100} accounts</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell>Ad Credit Rewards</TableCell>
          {hasEntry && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              {(premium?.ad_credit_reward_amount ?? 0) > 0 ? (
                <TooltipComponent
                  heading={<CheckIcon color="primary" />}
                  tooltip={`${numbro(premium?.ad_credit_reward_amount ?? 375).formatCurrency({
                    thousandSeparated: true,
                    mantissa: 0
                  })} for spending ${numbro(
                    premium?.ad_credit_reward_minimum_spend ?? 1500
                  ).formatCurrency({ thousandSeparated: true, mantissa: 0 })} per month`}
                />
              ) : (
                <CrossIcon color="error" />
              )}
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              {(premiumPlus?.ad_credit_reward_amount ?? 0) > 0 ? (
                <TooltipComponent
                  heading={<CheckIcon color="primary" />}
                  tooltip={`${numbro(premiumPlus?.ad_credit_reward_amount ?? 375).formatCurrency({
                    thousandSeparated: true,
                    mantissa: 0
                  })} for spending ${numbro(
                    premiumPlus?.ad_credit_reward_minimum_spend ?? 1500
                  ).formatCurrency({ thousandSeparated: true, mantissa: 0 })} per month`}
                />
              ) : (
                <CrossIcon color="error" />
              )}
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell colSpan={5} align="center">
            <Typography variant="subtitle2">
              <strong>Features</strong>
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Advanced Dashboard</TableCell>
          {hasEntry && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Posts</TableCell>
          {hasEntry && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Bulk Posts</TableCell>
          {hasEntry && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Head Office Content Schedule</TableCell>
          {hasEntry && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Ad Campaigns</TableCell>
          {hasEntry && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Campaign Promotion Pages</TableCell>
          {hasEntry && (
            <TableCell>
              <TooltipComponent
                heading={<CheckIcon color="secondary" />}
                tooltip="via head office only"
              />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <TooltipComponent
                heading={<CheckIcon color="secondary" />}
                tooltip="via head office only"
              />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Campaign Templates</TableCell>
          {hasEntry && (
            <TableCell>
              <TooltipComponent
                heading={<CheckIcon color="secondary" />}
                tooltip="via head office only"
              />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <TooltipComponent
                heading={<CheckIcon color="secondary" />}
                tooltip="via head office only"
              />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Simple Ad Campaigns</TableCell>
          {hasEntry && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Lead Gen Ad Campaigns</TableCell>
          {hasEntry && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Vendor Reports</TableCell>
          {hasEntry && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Creative</TableCell>
          {hasEntry && (
            <TableCell>
              <TooltipComponent
                heading={<CheckIcon color="secondary" />}
                tooltip="via head office only"
              />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Audiences</TableCell>
          {hasEntry && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Customer Reviews</TableCell>
          {hasEntry && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>RealShortz</TableCell>
          {hasEntry && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Listing Feed</TableCell>
          {hasEntry && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Automations</TableCell>
          {hasEntry && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <TooltipComponent
                heading={<CheckIcon color="secondary" />}
                tooltip="via head office only"
              />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
        {/* <TableRow>
          <TableCell>Approval Workflows</TableCell>
          {hasEntry && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow> */}
        <TableRow>
          <TableCell>API Developer Access</TableCell>
          {hasEntry && (
            <TableCell>
              <CrossIcon color="error" />
            </TableCell>
          )}
          {hasBasic && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremium && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
          {hasPremiumPlus && (
            <TableCell>
              <CheckIcon color="primary" />
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
}

export const useFeaturesAccordionStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    BoxShadow: 'none'
  }
}));
