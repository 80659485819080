import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

import { useSetVideoPendingMutation } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

type RocketiumContextType = {
  ready: boolean;
  enabled: boolean;
};

const defaultRocketiumContext: RocketiumContextType = {
  ready: false,
  enabled: false
};

const RocketiumContext = createContext<RocketiumContextType>(defaultRocketiumContext);

const RocketiumContextProvider = ({ children }: { children: ReactNode }) => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { workspace, isAdminMode } = useUserContext();

  const [updateVideo] = useSetVideoPendingMutation({ context: workspaceMemberContext });
  const [ready, setReady] = useState<boolean>(false);

  const videoEditorEnabled = useMemo(() => {
    if (isAdminMode) {
      return true;
    }
    return workspace?.video_editor_enabled ?? false;
  }, [isAdminMode, workspace]);

  useEffect(() => {
    if (!videoEditorEnabled) {
      return;
    }

    setTimeout(() => {
      // (window as any).rfsdk('on', 'rfsdk-video-exporting', (data: any) => {
      //   const { videoData } = data;
      //   const videoId = videoData._id;
      //   updateVideo({ variables: { rocketium_id: videoId } });
      //   (window as any).rfsdk('export');
      // });
      (window as any).rfsdk2.on('rfsdk-video-exporting', (data: any) => {
        const { videoData } = data;
        const videoId = videoData._id;
        updateVideo({ variables: { rocketium_id: videoId } });
        (window as any).rfsdk2.export();
      });

      // (window as any).rfsdk('on', 'rfsdk-editor-exit-clicked', () => {
      //   (window as any).rfsdk('save');
      // });
      (window as any).rfsdk2.on('rfsdk-editor-exit-clicked', () => {
        (window as any).rfsdk2.save();
      });

      setReady(true);
    }, 2500);
  }, [setReady, updateVideo, videoEditorEnabled]);

  const value = useMemo<RocketiumContextType>(
    () => ({ ready, enabled: videoEditorEnabled }),
    [ready, videoEditorEnabled]
  );

  return <RocketiumContext.Provider value={value}>{children}</RocketiumContext.Provider>;
};

export { RocketiumContext, RocketiumContextProvider };
