import { useEffect, useMemo } from 'react';

import AwsS3Multipart from '@uppy/aws-s3-multipart';
import Uppy, { UppyFile } from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import Dropbox from '@uppy/dropbox';
import GoogleDrive from '@uppy/google-drive';
import ImageEditor from '@uppy/image-editor';
import '@uppy/image-editor/dist/style.css';
import Url from '@uppy/url';
import '@uppy/url/dist/style.css';

import cuid from 'cuid';
import heic2any from 'heic2any';
import uniq from 'lodash/uniq';
import numbro from 'numbro';
import { v4 as uuid } from 'uuid';

import { FileAttachment } from 'components/types';

import UppyCanvaPlugin from 'lib/Uppy/CanvaPlugin';
import { UppyImageCompressorPlugin } from 'lib/Uppy/compression/image';
import { UppyHEICImageConversionPlugin } from 'lib/Uppy/heic/conversion';
import { getFilePath } from 'lib/auth/hbp';
import { uploadBase64Image } from 'lib/utils/uploadFile';
import { getVideoThumbnail } from 'lib/videos/thumbnails';

import {
  COMPANION_URL,
  DEFAULT_MAX_IMAGE_SIZE,
  DEFAULT_MAX_VIDEO_SIZE,
  IMAGE_CONTENT_TYPE,
  MAX_REMOTE_URL_FILE_SIZE,
  UppyFileCheckOptions,
  UppyFiles,
  UppyImageCompressionOptions,
  UppyImageEditorActions,
  UppyRestrictions,
  VIDEO_CONTENT_TYPE
} from './utils';

interface UppyHookProps {
  baseFileUploadPath: string;
  uppyRestrictions?: UppyRestrictions;
  onUppyCompleteSuccess?: (files: FileAttachment[]) => void;
  onUppyRemoveFile?: (file: UppyFile) => void;
  allowMultipleFileTypes?: boolean;
  uppyImageCompressionOptions?: UppyImageCompressionOptions;
  uppyImageEditorActions?: UppyImageEditorActions;
  uppyFileCheckOptions: UppyFileCheckOptions;
}

export function useUppyInstance({
  baseFileUploadPath,
  uppyRestrictions,
  onUppyCompleteSuccess,
  onUppyRemoveFile,
  allowMultipleFileTypes,
  uppyImageCompressionOptions,
  uppyImageEditorActions,
  uppyFileCheckOptions
}: UppyHookProps) {
  const uppy = useMemo(() => {
    return new Uppy({
      debug: true,
      autoProceed: false,
      allowMultipleUploadBatches: true,
      restrictions: uppyRestrictions,
      onBeforeFileAdded: (currentFile, files) => {
        const { type, size, source } = currentFile;

        const maxImageSize = uppyFileCheckOptions.maxImageSize ?? DEFAULT_MAX_IMAGE_SIZE;
        const maxVideoSize = uppyFileCheckOptions.maxVideoSize ?? DEFAULT_MAX_VIDEO_SIZE;

        if (!type) {
          uppy.log(`Skipping file because it has no type`);
          uppy.info(`Skipping file because it has no type`, 'error');
          return false;
        }

        if (!size) {
          uppy.log(`Skipping file because it has no size`);
          uppy.info(`Skipping file because it has no size`, 'error');
          return false;
        }

        // Images limited to 25MB
        if (type.includes(IMAGE_CONTENT_TYPE) && size > maxImageSize) {
          uppy.info(
            `Image is too large. Max image size is ${numbro(maxImageSize).format({
              output: 'byte',
              base: 'decimal'
            })}.`,
            'error'
          );
          return false;
        }

        // Videos limited to 1GB
        if (type.includes(VIDEO_CONTENT_TYPE) && size > maxVideoSize) {
          uppy.info(
            `Video is too large. Max video size is ${numbro(maxVideoSize).format({
              output: 'byte',
              base: 'decimal'
            })}.`,
            'error'
          );
          return false;
        }

        // Remote URL Images Limited to 8MB
        if (
          source === 'Url' &&
          type.includes(IMAGE_CONTENT_TYPE) &&
          size > MAX_REMOTE_URL_FILE_SIZE
        ) {
          uppy.info('File too large. Url images restricted to 8MB.', 'error');
          return false;
        }

        const allFileTypes = Object.keys(files)
          .map((key) => files[key].type!)
          .filter(Boolean);
        const uniqueFileTypes = uniq(allFileTypes);

        const hasImages = uniqueFileTypes.some((type) => type.includes(IMAGE_CONTENT_TYPE));
        const hasVideos = uniqueFileTypes.some((type) => type.includes(VIDEO_CONTENT_TYPE));

        // Check if we have mix of videos and images
        if (
          !allowMultipleFileTypes &&
          ((hasImages && type.includes(VIDEO_CONTENT_TYPE)) ||
            (hasVideos && type.includes(IMAGE_CONTENT_TYPE)))
        ) {
          uppy.info('You cannot mix Images and Video files', 'error');
          return false;
        }

        return true;
      },
      onBeforeUpload: (files) => {
        const updatedFiles: UppyFiles = {};

        Object.keys(files).forEach((fileId) => {
          const file = files[fileId];
          const slug = cuid.slug();
          const token = uuid();
          const name = file.name
            .replace(/'/g, '_')
            .replace(/\s+/g, '_')
            .replace(/[^a-zA-Z.0-9]/g, '')
            .trim();
          const filename = `${slug}_${name}`;
          const path = `${baseFileUploadPath}/${filename}`;

          updatedFiles[fileId] = {
            ...file,
            name: filename,
            meta: {
              ...file.meta,
              name: name,
              path,
              filename,
              token
            }
          };
        });

        return updatedFiles;
      }
    })
      .on('file-added', async (file) => {
        try {
          if (file.extension.toLowerCase() === 'heic') {
            const newBlob = await heic2any({
              blob: file.data,
              quality: 0.8,
              toType: 'image/jpeg'
            });

            // Update filename/path/extension to be jpeg
            const FILE_EXT_REGEX = /\.[^/.]+$/;
            uppy.removeFile(file.id);
            uppy.addFile({
              data: newBlob as Blob,
              extension: 'jpeg',
              type: 'image/jpeg',
              name: file.name.replace(FILE_EXT_REGEX, '.jpeg'),
              meta: {
                ...file.meta,
                name: file.meta.name.replace(FILE_EXT_REGEX, '.jpeg'),
                type: 'image/jpeg'
              }
            });
          }
        } catch (error: any) {
          uppy.log(error?.message ?? 'Could not convert HEIC file', 'error');
        }

        if (file.type?.includes('video')) {
          try {
            const videoThumb = await getVideoThumbnail(file.data as File, 1);

            if (videoThumb) {
              const slug = cuid();
              const path = `${baseFileUploadPath}/${slug}-thumb.jpeg`;
              const filename = `${slug}-thumb.jpeg`;

              const image = await uploadBase64Image(path, filename, videoThumb);

              uppy.setFileState(file.id, {
                preview: getFilePath(image.path, image.token),
                generated_thumbnail_path: image.path
              });
            }
          } catch (error: any) {
            console.warn(error);
            uppy.log(error?.message ?? 'Could not generate thumbnails', 'error');
          }
        }
      })
      .on('file-removed', (file) => {
        if (onUppyRemoveFile) {
          onUppyRemoveFile(file);
        }
      })
      .on('complete', (result) => {
        if (result.failed.length) {
          console.error(result.failed);
          uppy.info('Some files failed to upload', 'error');
        }

        // Successfully
        const results: FileAttachment[] = result.successful.map((file) => ({
          key: file.meta.path as string,
          token: file.meta.token as string,
          filename: file.meta.filename as string,
          contenttype: file.type as string,
          generated_thumbnail_path: (file as any).generated_thumbnail_path as string | undefined
        }));

        if (onUppyCompleteSuccess) {
          onUppyCompleteSuccess(results);
        }
      })
      .use(ImageEditor, {
        quality: 0.9,
        cropperOptions: {
          minCropBoxHeight: 720,
          minCropBoxWidth: 720,
          croppedCanvasOptions: {
            minHeight: 720,
            minWidth: 720
          }
        },
        actions: uppyImageEditorActions
      })
      .use(Url, {
        companionUrl: COMPANION_URL,
        companionHeaders: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      .use(Dropbox, {
        companionUrl: COMPANION_URL
      })
      .use(GoogleDrive, {
        companionUrl: COMPANION_URL
      })
      .use(UppyCanvaPlugin)
      .use(UppyHEICImageConversionPlugin, {})
      .use(UppyImageCompressorPlugin, {
        checkOrientation: false,
        quality: uppyImageCompressionOptions?.quality ?? 0.9,
        maxWidth: uppyImageCompressionOptions?.maxWidth ?? 1920,
        maxHeight: uppyImageCompressionOptions?.maxHeight ?? 1920,
        mimeType: uppyImageCompressionOptions?.type ?? 'jpeg',
        type: uppyImageCompressionOptions?.type ?? 'jpeg',
        convertTypes: uppyImageCompressionOptions?.convertTypes ?? [
          'image/jpg',
          'image/jpeg',
          'image/png',
          'image/webp',
          'image/heic'
        ]
      })
      .use(AwsS3Multipart, {
        limit: 5,
        companionUrl: COMPANION_URL,
        companionHeaders: {
          'Access-Control-Allow-Origin': '*'
        }
      });
  }, [
    baseFileUploadPath,
    uppyRestrictions,
    onUppyCompleteSuccess,
    onUppyRemoveFile,
    allowMultipleFileTypes,
    uppyImageCompressionOptions,
    uppyImageEditorActions,
    uppyFileCheckOptions
  ]);

  useEffect(() => {
    return () => uppy.close();
  }, [uppy]);

  return uppy;
}
