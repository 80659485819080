import React, { useMemo } from 'react';

import { FormSpy } from 'react-final-form';

import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { TextField } from 'mui-rff';

import {
  AvailableTemplatesQuery,
  CreativeTemplateTypeEnum,
  GetHeadOfficeBrandCreativeTemplatesQuery,
  TemplateTypeEnum,
  Workspace_Template_Creative_Type_Enum,
  Workspace_Template_Type_Enum,
  useAvailableTemplatesQuery,
  useGetHeadOfficeBrandCreativeTemplatesQuery
} from 'generated/graphql';

import PreviewIcon from 'components/TemplatePreviewIcon';
import { DynamicFunctionParametersInputWrapperTemplate } from 'components/creative/dynamicParameters';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';
import { getPossibleNestedValue } from 'lib/utils/getPossibleNestedValue';

export interface FinalFormTemplateSelectorProps {
  name: string;
  label: string;
  disabled?: boolean;
  creativeType?: CreativeTemplateTypeEnum;
  templateType?: TemplateTypeEnum;
  clearable?: boolean;
  required?: boolean;
  showDynamicParameters?: boolean;
  dynamicParametersFormKeyPrefix?: string;
  noTemplateOptionLabel?: string;
}

interface UseAvailableTemplatesPrams {
  creativeType: CreativeTemplateTypeEnum;
  templateType: TemplateTypeEnum;
}

function useAvailableTemplates({ creativeType, templateType }: UseAvailableTemplatesPrams) {
  const { workspaceMemberContext, headOfficeUserContext } = useHasuraRoleContext();
  const { activeWorkspaceId, activeHeadOfficeId, isHeadOfficeMode } = useUserContext();

  const headOfficeTemplatesResponse = useGetHeadOfficeBrandCreativeTemplatesQuery({
    variables: {
      where: {
        _and: [
          {
            brand_packs: {
              pack: { head_offices: { id: { _eq: activeHeadOfficeId! } } }
            }
          },
          {
            creative_type: {
              _eq:
                creativeType === 'ALL' || creativeType === 'RESPONSIVE'
                  ? Workspace_Template_Creative_Type_Enum.Image
                  : (creativeType.toLowerCase() as unknown as Workspace_Template_Creative_Type_Enum)
            }
          },
          {
            template_type: { _eq: templateType as unknown as Workspace_Template_Type_Enum }
          },
          {
            version: { _gt: 1 }
          }
        ]
      }
    },
    context: headOfficeUserContext!,
    skip: !isHeadOfficeMode
  });

  const workspaceTemplatesResponse = useAvailableTemplatesQuery({
    variables: {
      args: {
        workspace_id: activeWorkspaceId!,
        creative_type: creativeType,
        template_type: templateType
      }
    },
    context: workspaceMemberContext,
    skip: isHeadOfficeMode
  });

  if (isHeadOfficeMode) {
    return headOfficeTemplatesResponse;
  }

  return workspaceTemplatesResponse;
}

export const FinalFormTemplateSelector = React.forwardRef<any, FinalFormTemplateSelectorProps>(
  (
    {
      name,
      label,
      disabled = false,
      creativeType = CreativeTemplateTypeEnum.Image,
      templateType = TemplateTypeEnum.PropertySingle,
      clearable = false,
      showDynamicParameters = true,
      required = false,
      dynamicParametersFormKeyPrefix,
      noTemplateOptionLabel = 'No template'
    }: FinalFormTemplateSelectorProps,
    ref
  ) => {
    const { data, loading, error } = useAvailableTemplates({ creativeType, templateType });

    const availableTemplates = useMemo(() => {
      if (!data) {
        return [];
      }

      if ((data as AvailableTemplatesQuery).getWorkspaceTemplates) {
        return (data as AvailableTemplatesQuery).getWorkspaceTemplates;
      }

      if ((data as GetHeadOfficeBrandCreativeTemplatesQuery).workspace_creative_custom_templates) {
        return (data as GetHeadOfficeBrandCreativeTemplatesQuery)
          .workspace_creative_custom_templates;
      }

      return [];
    }, [data]);

    const validTemplateIds = useMemo(
      () => availableTemplates.map((at) => at.template_id),
      [availableTemplates]
    );

    if (error) {
      return (
        <div ref={ref}>
          <Typography variant="caption" color="error" gutterBottom>
            Error fetching templates
          </Typography>
        </div>
      );
    }

    if (loading) {
      return (
        <div ref={ref}>
          <Skeleton>
            {/* For skeleton auto size */}
            <TextField name={name} />
          </Skeleton>
        </div>
      );
    }

    return (
      <div ref={ref}>
        <FormSpy
          subscription={{ values: true }}
          render={({ form, values }) => {
            if (!availableTemplates.length) {
              form.change(name, null);
            }

            const previewTemplateId = getPossibleNestedValue(values, name) as
              | AvailableTemplatesQuery['getWorkspaceTemplates'][number]['template_id']
              | undefined;

            if (previewTemplateId && !validTemplateIds.includes(previewTemplateId)) {
              form.change(name, null);
            }

            return (
              <>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={11}>
                    <TextField
                      name={name}
                      label={label}
                      select
                      disabled={disabled}
                      required={required}
                    >
                      <MenuItem
                        disabled={disabled || !clearable}
                        onClick={() => form.change(name, null)}
                      >
                        {noTemplateOptionLabel}
                      </MenuItem>
                      {availableTemplates.map((template) => (
                        <MenuItem
                          key={template.id}
                          value={template.template_id}
                          disabled={disabled}
                        >
                          {template.name}
                        </MenuItem>
                      ))}
                      {!availableTemplates.length && (
                        <MenuItem disabled>No templates available</MenuItem>
                      )}
                    </TextField>
                  </Grid>
                  <Grid item xs={1}>
                    <PreviewIcon templateId={previewTemplateId} templates={availableTemplates} />
                  </Grid>
                </Grid>
                <Box>
                  {showDynamicParameters && previewTemplateId && (
                    <DynamicFunctionParametersInputWrapperTemplate
                      templateId={previewTemplateId}
                      form={form}
                      formKeyPrefix={dynamicParametersFormKeyPrefix}
                      disabled={disabled}
                    />
                  )}
                </Box>
              </>
            );
          }}
        />
      </div>
    );
  }
);
