import { useContext } from 'react';

import { RocketiumContext } from 'lib/RocketiumContext';

const useRocketiumContext = () => {
  const rc = useContext(RocketiumContext);
  const rfsdk = (window as any).rfsdk;
  const rfsdk2 = (window as any).rfsdk2;

  return {
    ready: rc.ready,
    enabled: rc.enabled,
    rfsdk: rfsdk,
    rfsdk2: rfsdk2
  };
};

export default useRocketiumContext;
