import React from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Paper,
  Theme,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import FacebookLogo from 'mdi-material-ui/Facebook';

import useAccountConnectionStyles from './_styles';

export interface AccountConnectionProps {
  title: string;
  details: string;
  connected: boolean;
  buttonText: string;
  buttonOnClick: () => void;
  buttonDisabled?: boolean;
  secondButton?: boolean;
  secondButtonText?: string;
  secondButtonOnClick?: () => void;
  secondButtonDisabled?: boolean;
  extraInfo?: string;
  loading?: boolean;
  elevation?: number;
  platform?: 'facebook';
}

const AccountConnection = ({
  title,
  details,
  connected,
  buttonOnClick,
  buttonText,
  buttonDisabled,
  secondButton = false,
  secondButtonText,
  secondButtonOnClick,
  secondButtonDisabled,
  extraInfo,
  loading = false,
  elevation = 1,
  platform
}: AccountConnectionProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const styles = useAccountConnectionStyles();
  const isFacebook = platform === 'facebook';

  const btnColor = connected ? 'default' : 'primary';

  // Facebook Specific Styles
  const btnStyle = isFacebook
    ? {
        backgroundColor: '#1877F2',
        color: '#FFFFFF'
      }
    : undefined;
  const btnIcon = isFacebook ? <FacebookLogo /> : undefined;

  return (
    <Paper className={styles.container} elevation={elevation}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: isMobile ? 'column' : 'row'
        }}
      >
        <Box>
          <Typography variant="body1">{title}</Typography>
          {loading ? (
            <Skeleton variant="text" />
          ) : (
            <Typography variant="caption">{details}</Typography>
          )}
        </Box>
        <Box>
          {loading ? (
            <Skeleton variant="rect" width={120} height={40} />
          ) : (
            <ButtonGroup variant="contained" size={isMobile ? 'medium' : 'large'}>
              <Button
                color={btnColor}
                onClick={buttonOnClick}
                disabled={buttonDisabled}
                style={btnStyle}
                startIcon={btnIcon}
              >
                {buttonText}
              </Button>
              {secondButton && (
                <Button
                  color="inherit"
                  onClick={secondButtonOnClick}
                  disabled={secondButtonDisabled}
                >
                  {secondButtonText}
                </Button>
              )}
            </ButtonGroup>
          )}
        </Box>
      </Box>
      <Box className={styles.extraInfoBox}>
        <Typography variant="body2">{extraInfo}</Typography>
      </Box>
    </Paper>
  );
};

export default AccountConnection;
