import { useEffect } from 'react';

import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
  Typography
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import GroupIcon from '@material-ui/icons/Group';
import { Skeleton } from '@material-ui/lab';

import CommentEditIcon from 'mdi-material-ui/CommentEditOutline';
import moment from 'moment';

import { useCreativeByPkQuery, Workspace_Creative_Types_Enum } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { getFilePath } from 'lib/auth/hbp';
import useUserContext from 'lib/hooks/useUserContext';

import DynamicCreativeCard from '../DynamicCreativeCard';
import ImageCreativeCard from '../ImageCreativeCard';
import ManualCreativeCard from '../ManualCreativeCard';
import ManualVideoCreativeCard from '../ManualVideoCreativeCard';
import ResponsiveCarouselCreativeCard from '../ResponsiveCarouselCreativeCard';
import VideoCreativeCard from '../VideoCreativeCard';

interface CreativeCardProps {
  id?: string;
  onRefetchRequired?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    minHeight: 360,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  media: {
    position: 'relative',
    color: 'white',
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain'
  }
}));

export default function CreativeCard({ id, onRefetchRequired }: CreativeCardProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { isWorkspaceAnalyst } = useUserContext();

  const classes = useStyles();

  const { data, loading, refetch, stopPolling } = useCreativeByPkQuery({
    variables: { id: id! },
    context: workspaceMemberContext,
    skip: !id,
    pollInterval: 30000 // Check every 30 seconds for updates on ready state
  });

  const creative = data?.creative;
  const ready = Boolean(creative?.ready);

  useEffect(() => {
    // No need to continue to poll for ready state
    if (ready) {
      stopPolling();
    }
  }, [ready, stopPolling]);

  const handleRefetch = () => {
    // If a users deletes a creative we need to remove it from the
    // grid of rendered items
    if (onRefetchRequired) {
      onRefetchRequired();
    }
    refetch();
  };

  if (loading || !id) {
    return (
      <Card className={classes.root} elevation={2}>
        <CardActionArea disabled>
          <Skeleton animation="wave" variant="rect" className={classes.media} />
        </CardActionArea>
        <CardContent>
          <Skeleton variant="text" height={16} width="60%" />
          <Skeleton variant="text" height={16} width="80%" />
        </CardContent>
        <CardActions>
          <IconButton aria-label="Assign Agents" disabled={true}>
            <GroupIcon />
          </IconButton>
          <IconButton aria-label="Edit Ad Copy" disabled={true}>
            <CommentEditIcon />
          </IconButton>
        </CardActions>
      </Card>
    );
  }

  if (!creative) {
    return (
      <Card className={classes.root} elevation={2}>
        <CardActionArea disabled>
          <Skeleton animation="wave" variant="rect" className={classes.media} />
        </CardActionArea>
        <CardContent>
          <Typography variant="overline">Error Loading Creative</Typography>
          <Typography variant="h6">Error</Typography>
        </CardContent>
        <CardActions>
          <IconButton aria-label="Assign Agents" disabled={true}>
            <GroupIcon />
          </IconButton>
          <IconButton aria-label="Edit Ad Copy" disabled={true}>
            <CommentEditIcon />
          </IconButton>
        </CardActions>
      </Card>
    );
  }

  const creationTimeText = moment(creative.available_at ?? creative.created_at).fromNow();

  const type = creative.type;
  if (type === Workspace_Creative_Types_Enum.Video) {
    const thumbnailUrl = getFilePath(
      creative.video_thumbnail?.s3_path ?? creative.video?.thumbnail_url
    );

    return (
      <VideoCreativeCard
        id={creative.id}
        title={creative.title}
        date={creationTimeText}
        tags={creative.tags}
        src={thumbnailUrl}
        downloadUrl={getFilePath(creative.video?.video_url)}
        ready={creative.ready}
        onRefetchRequired={handleRefetch}
        disableActionButtons={isWorkspaceAnalyst}
        draft={creative.video?.draft}
        rocketiumId={creative.video?.rocketium_id}
        listingId={creative.listings?.length === 1 ? creative.listings[0].listing_id : undefined}
        hasBeenPosted={(creative.posts_aggregate?.aggregate?.count ?? 0) > 0}
        createdByUserId={creative.created_by_id}
      />
    );
  } else if (type === Workspace_Creative_Types_Enum.Image) {
    return (
      <ImageCreativeCard
        id={creative.id}
        title={creative.title}
        ready={creative.ready}
        date={creationTimeText}
        tags={creative.tags}
        images={creative.images}
        listingId={creative.listings?.length === 1 ? creative.listings[0].listing_id : undefined}
        onRefetchRequired={handleRefetch}
        createdByUserId={creative.created_by_id}
      />
    );
  } else if (type === Workspace_Creative_Types_Enum.Manual) {
    return (
      <ManualCreativeCard
        id={creative.id}
        title={creative.title}
        url={creative.default_url}
        ready={creative.ready}
        date={creationTimeText}
        tags={creative.tags}
        attachments={creative.attachments}
        onRefetchRequired={handleRefetch}
        createdByUserId={creative.created_by_id}
      />
    );
  } else if (type === Workspace_Creative_Types_Enum.ManualVideo) {
    const thumbnailUrl =
      getFilePath(creative.video_thumbnail?.s3_path) ?? creative.cloudinary_video?.thumbnail_url;
    return (
      <ManualVideoCreativeCard
        id={creative.id}
        title={creative.title}
        videoUrl={creative.cloudinary_video?.video_url}
        thumbnailUrl={thumbnailUrl}
        ready={creative.ready}
        date={creationTimeText}
        tags={creative.tags}
        onRefetchRequired={handleRefetch}
        createdByUserId={creative.created_by_id}
      />
    );
  } else if (type === Workspace_Creative_Types_Enum.Responsive) {
    return (
      <ResponsiveCarouselCreativeCard
        id={creative.id}
        title={creative.title}
        ready={creative.ready}
        date={creationTimeText}
        tags={creative.tags}
        attachments={creative.attachments ?? creative.responsive?.images ?? []}
        onRefetchRequired={handleRefetch}
        type={type}
        createdByUserId={creative.created_by_id}
      />
    );
  } else if (type === Workspace_Creative_Types_Enum.Carousel) {
    return (
      <ResponsiveCarouselCreativeCard
        id={creative.id}
        title={creative.title}
        ready={creative.ready}
        date={creationTimeText}
        tags={creative.tags}
        attachments={
          creative.attachments ?? creative.carousel?.items?.flatMap((item) => item.images) ?? []
        }
        type={type}
        onRefetchRequired={handleRefetch}
        createdByUserId={creative.created_by_id}
      />
    );
  } else if (type === Workspace_Creative_Types_Enum.Dynamic) {
    return (
      <DynamicCreativeCard
        id={creative.id}
        title={creative.title}
        ready={creative.ready}
        date={creationTimeText}
        tags={creative.tags}
        attachments={creative.attachments}
        type={type}
        onRefetchRequired={handleRefetch}
        dynamic={creative.dynamic!}
        createdByUserId={creative.created_by_id}
      />
    );
  }

  return null;
}
